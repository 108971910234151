import * as api from '../../api/login_api';
import { SetStateMessages, GetObservableMessages } from '../models/models';
import { BehaviorSubject } from 'rxjs';
import isEmail from 'validator/lib/isEmail';
const state = {
    failedLoginInformation: new BehaviorSubject(''),
    newPasswordRequestInformation: new BehaviorSubject(null),
    requestAccessInformation: new BehaviorSubject(null),
    locationOptions: new BehaviorSubject([]),
};
export function setState(message) {
    switch (message.type) {
        case SetStateMessages.LOGIN:
            attemptLogin(message.data);
            break;
        case SetStateMessages.REQUEST_NEW_PASSWORD:
            return requestNewPassword(message.data);
        case SetStateMessages.REQUEST_ACCESS:
            requestAccess(message.data);
            break;
    }
}
export function getObservable(message) {
    switch (message) {
        case GetObservableMessages.FAILED_LOGIN_INFORMATION:
            return state.failedLoginInformation.asObservable();
        case GetObservableMessages.REQUEST_NEW_PASSWORD_INFORMATION:
            return state.newPasswordRequestInformation.asObservable();
        case GetObservableMessages.REQUEST_ACCESS_INFORMATION:
            return state.requestAccessInformation.asObservable();
        case GetObservableMessages.LOCATION_OPTIONS:
            return state.locationOptions.asObservable();
    }
}
function attemptLogin(credentials) {
    api.login(credentials).then(response => {
        if (response.status === 200) {
            window.location.replace(response.url);
        }
        else if (response.status === 401) {
            response.json().then(json => {
                state.failedLoginInformation.next(calculateWarningMessage(json.Warning));
            });
        }
    });
}
function requestNewPassword(email) {
    const emailValid = isEmail(email);
    if (emailValid) {
        api.requestNewPassword(email).then(response => {
            if (response.Warning) {
                state.newPasswordRequestInformation.next({
                    info: calculateWarningMessage(response.Warning),
                    success: false,
                });
            }
            else if (response.Success) {
                state.newPasswordRequestInformation.next({
                    info: 'access_request_submitted_content',
                    success: true,
                });
            }
        });
    }
    return emailValid;
}
function requestAccess(data) {
    const emailValid = isEmail(data.email);
    api.requestNewAccount(data.email, data.location).then(response => {
        if (response.Warning &&
            response.Warning.includes('Choose location')) {
            state.locationOptions.next(response.GroupNames);
        }
        else if (response.Warning &&
            response.Warning === 'New user was added') {
            state.requestAccessInformation.next({
                info: 'access_request_submitted_content',
                success: true,
            });
        }
        else {
            if (response.Warning && response.Warning !== '') {
                state.requestAccessInformation.next({
                    info: calculateWarningMessage(response.Warning),
                    success: false,
                });
            }
            else if (response.Info && response.Info !== '') {
                state.requestAccessInformation.next({
                    info: calculateInfoMessage(response.Info),
                    success: false,
                });
            }
        }
    });
    return emailValid;
}
function calculateWarningMessage(warning) {
    if (warning.includes('duplicate sessions')) {
        return 'duplicate_sessions';
    }
    if (warning.includes('blocked')) {
        return 'blocked_user_content';
    }
    if (warning.includes('Unrecognised client')) {
        return 'unknown_domain_content';
    }
    if (warning.includes('incorrect username')) {
        return 'not_registered_content';
    }
    if (warning.includes('incorrect password')) {
        return 'password_incorrect_content';
    }
    if (warning.includes('not an email')) {
        return 'not_email_address_content';
    }
    if (warning.includes('no domain or username')) {
        return 'no_username_provided_content';
    }
    if (warning.includes('username does not exist')) {
        return 'not_registered_content';
    }
}
function calculateInfoMessage(info) {
    if (info.includes('email address domain')) {
        return 'unknown_domain_content';
    }
    if (info.includes('already in use')) {
        return 'email_in_use_content';
    }
    if (info.includes('not registered')) {
        return 'not_registered_content2';
    }
}
