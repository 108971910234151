var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { ButtonStyles } from './styles/button';
import { BaseElement } from '../base_element/element';
export var isLoading;
(function (isLoading) {
    isLoading["loading"] = "load";
    isLoading["done"] = "done";
})(isLoading || (isLoading = {}));
;
export var Variant;
(function (Variant) {
    Variant["primary"] = "dn-button";
    Variant["secondary"] = "dn-button--secondary";
    Variant["tertiary"] = "dn-button--tertiary";
    Variant["success"] = "dn-button--success";
    Variant["warning"] = "dn-button--warning";
    Variant["grey"] = "dn-button--grey";
    Variant["discard"] = "dn-button--discard";
})(Variant || (Variant = {}));
;
let CommonButton = class CommonButton extends BaseElement {
    set disabled(bool) {
        const oldBool = this.disabled;
        this._disabled = bool;
        this.requestUpdate('disabled', oldBool);
    }
    get disabled() {
        return this._disabled;
    }
    constructor() {
        super();
        this.variant = Variant.primary;
        this.loading = 'done';
        this.submitted = false;
        this.noPointerEvents = false;
        this.noShadow = false;
        this._disabled = false;
    }
    render() {
        return html `
        <div @mousedown="${(e) => this.ripple(e)}">
            <button
                class="base-button ${this.variant} ${this.noShadow ? '' : 'button-shadow'}"
                data-animation="ripple"
                .disabled=${this.disabled}
                @click="${(e) => this.clickEvent(e)}"
                style="${'height: ' + this.height + 'px;'} ${this.textColor ? `color: ${this.textColor} !important;` : ''}"
            >
            ${this.svgIcon ? html `
                <dn-common-icon svg="${this.svgIcon}"></dn-common-icon>
            ` : ''}
            ${this.icon ? html `
                <dn-common-icon fontawesomeIcon="${this.icon}"></dn-common-icon>
            ` : ''}
            ${this.loading === 'load' ? html `
                    <img src="/static/ui/common/css/images/loading_white.svg"/>
                ` : this.calculateButtonText()}</button>
        </div>
        `;
    }
    calculateButtonText() {
        if (this.text) {
            return this.text[0].toUpperCase() + this.text.substr(1).toLowerCase();
        }
        else {
            return '';
        }
    }
    firstUpdated() {
        super.firstUpdated();
        if (this.isTablet) {
            // Take the currently set height, and multiply it by 0.8 for tablet
            this.height = this.height * 0.8;
        }
        else if (this.isMobile) {
            this.height = this.height * 0.8;
        }
    }
    ripple(e) {
        const ripple = document.createElement('span');
        ripple.className = 'ripple';
        ripple.style.left = '87px';
        ripple.style.top = '-47px';
        ripple.style.setProperty('--scale', '348');
        e.target.appendChild(ripple);
    }
    clickEvent(e) {
        e.stopPropagation();
        setTimeout(() => {
            super.dispatchEvent(new Event('click'));
        }, 150);
    }
};
CommonButton.styles = ButtonStyles;
__decorate([
    property({ type: String })
], CommonButton.prototype, "text", void 0);
__decorate([
    property({ type: String })
], CommonButton.prototype, "variant", void 0);
__decorate([
    property({ type: String })
], CommonButton.prototype, "loading", void 0);
__decorate([
    property({ type: Boolean })
], CommonButton.prototype, "submitted", void 0);
__decorate([
    property({ reflect: true })
], CommonButton.prototype, "noPointerEvents", void 0);
__decorate([
    property({ type: Number })
], CommonButton.prototype, "height", void 0);
__decorate([
    property({ type: String })
], CommonButton.prototype, "icon", void 0);
__decorate([
    property({ type: String })
], CommonButton.prototype, "svgIcon", void 0);
__decorate([
    property({ type: Boolean })
], CommonButton.prototype, "noShadow", void 0);
__decorate([
    property({ type: String })
], CommonButton.prototype, "textColor", void 0);
__decorate([
    state()
], CommonButton.prototype, "_disabled", void 0);
__decorate([
    property({ type: Boolean })
], CommonButton.prototype, "disabled", null);
CommonButton = __decorate([
    customElement('dn-common-button')
], CommonButton);
export default CommonButton;
