import { BehaviorSubject } from 'rxjs';
import { UAParser } from 'ua-parser-js';
let instance;
export class Viewport {
    // Check to see whether singleton is instantiated more then once....
    /*
        private static readonly myStaticID = viewport.genID();
        private static genID(): string {
           return Math.random().toString(36).substr(2, 9);
        }
    */
    getBoundFunction(fn) {
        if (!this.boundFunctions.has(fn)) {
            this.boundFunctions.set(fn, fn.bind(this));
        }
        return this.boundFunctions.get(fn);
    }
    constructor() {
        this.parser = new UAParser();
        this.viewportReady = new BehaviorSubject(false);
        this.resizeReady = new BehaviorSubject(false);
        this.boundFunctions = new WeakMap();
        if (instance) {
            throw new Error("You can only create one instance!");
        }
        instance = this;
        let resizeTimeout;
        const debounceResize = (callback, delay) => {
            return () => {
                if (resizeTimeout) {
                    clearTimeout(resizeTimeout);
                }
                resizeTimeout = setTimeout(() => {
                    callback();
                }, delay);
            };
        };
        const resize = this.getBoundFunction(this.resizeBodyClasses);
        window.addEventListener("load", () => {
            this.setBodyClasses();
        });
        window.addEventListener("resize", () => {
            debounceResize(resize, 300)();
        });
        window.addEventListener("orientationchange", () => {
            debounceResize(resize, 300)();
        });
    }
    getInstance() {
        return this;
    }
    setBodyClasses() {
        if (this.isMobile) {
            if (!document.body.classList.contains('device-type-mobile')) {
                document.body.classList.remove("device-type-desktop");
                document.body.classList.remove("device-type-tablet");
                document.body.classList.add('device-type-mobile');
            }
        }
        else if (this.isTablet) {
            if (!document.body.classList.contains('device-type-tablet')) {
                document.body.classList.remove("device-type-desktop");
                document.body.classList.add("device-type-tablet");
                document.body.classList.remove("device-type-mobile");
            }
        }
        else if (this.isDesktop) {
            if (!document.body.classList.contains('device-type-desktop')) {
                document.body.classList.add("device-type-desktop");
                document.body.classList.remove("device-type-tablet");
                document.body.classList.remove("device-type-mobile");
            }
        }
        this.viewportReady.next(true);
    }
    resizeBodyClasses() {
        if (this.isMobileWidth) {
            if (!document.body.classList.contains('device-type-mobile')) {
                document.body.classList.remove("device-type-desktop");
                document.body.classList.remove("device-type-tablet");
                document.body.classList.add('device-type-mobile');
            }
        }
        else if (this.isTabletWidth) {
            if (!document.body.classList.contains('device-type-tablet')) {
                document.body.classList.remove("device-type-desktop");
                document.body.classList.add("device-type-tablet");
                document.body.classList.remove("device-type-mobile");
            }
        }
        else if (this.isDesktopWidth) {
            if (!document.body.classList.contains('device-type-desktop')) {
                document.body.classList.add("device-type-desktop");
                document.body.classList.remove("device-type-tablet");
                document.body.classList.remove("device-type-mobile");
            }
        }
        this.resizeReady.next(true);
    }
    get isReady() {
        return this.viewportReady;
    }
    get isResized() {
        return this.resizeReady;
    }
    get isMobile() {
        this.device = this.parser.getDevice();
        if (this.device.type !== 'tablet') {
            if (this.device.type === 'mobile' || this.device.type === 'wearable') {
                return true;
            }
            if (window.innerWidth < Viewport.mobileBreakpoint) {
                return true;
            }
        }
        return false;
    }
    get isMobileWidth() {
        if (window.innerWidth < Viewport.mobileBreakpoint) {
            return true;
        }
        else {
            return false;
        }
    }
    get isLandscapeMobile() {
        return this.isMobile && this.isLandscape;
    }
    get isPortraitMobile() {
        return this.isMobile && this.isPortrait;
    }
    get isTablet() {
        this.device = this.parser.getDevice();
        if (!this.isMobile) {
            if (this.device.type === 'tablet') {
                return true;
            }
            if (window.innerWidth >= Viewport.mobileBreakpoint && window.innerWidth < Viewport.tabletBreakpoint) {
                return true;
            }
        }
        return false;
    }
    get isTabletWidth() {
        if (window.innerWidth >= Viewport.mobileBreakpoint && window.innerWidth < Viewport.tabletBreakpoint) {
            return true;
        }
        else {
            return false;
        }
    }
    get isLandscapeTablet() {
        return this.isTablet && this.isLandscape;
    }
    get isPortraitTablet() {
        return this.isTablet && this.isPortrait;
    }
    get isDesktop() {
        this.device = this.parser.getDevice();
        if (!this.isMobile && !this.isTablet) {
            return true;
        }
        return false;
    }
    get isDesktopWidth() {
        if (window.innerWidth >= Viewport.tabletBreakpoint) {
            return true;
        }
        else {
            return false;
        }
    }
    get isLandscapeDesktop() {
        return this.isDesktop && this.isLandscape;
    }
    get isPortraitDesktop() {
        return this.isDesktop && this.isPortrait;
    }
    get isLandscape() {
        return window.matchMedia("(orientation: landscape)").matches;
    }
    get isPortrait() {
        return window.matchMedia("(orientation: portrait)").matches;
    }
}
Viewport.mobileBreakpoint = 768;
Viewport.tabletBreakpoint = 1200;
// Singleton pattern
export const viewport = new Viewport();
