import { css } from 'lit';
export const styles = css `

:host {
    margin-top:13px;
}

.float-container {
    border: 1px solid #D5DAE0;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 6px 8px;
    position: relative;
    width: 100%;
    height: 56px;
}

.no_border {
    border: 0 !important;
}

.float-container input {
    border: none;
    outline: 0;
    padding: 4px 0 0 0.5em;    
    width: 100%;
    background-color: transparent;
    height: 36px;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 400;
}

.float-container input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px #FFF inset;
        transition: background-color 5000s ease-in-out 0s;
    }

.float-container textarea {
    height: 4rem;
}

.float-container select:focus {
    outline-style:none;
    box-shadow:none;
    border-color:transparent;
}

.float-textarea-container {
    position: relative;
}

.input_label {
    position: absolute;
    background-color: transparent;
    padding: 0 .5rem;
    height: 42px;
    font-size: 14px;
    color: rgba(0,0,0,.6);
    display: flex;
    align-items: center;
    cursor: text;
    pointer-events: none;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 400;
}

.text_area_label {
    position: absolute;
    background-color: transparent;
    margin: 0 .5rem;
    padding: 0 .5rem;
    height: 42px;
    font-size: 14px;
    color: rgba(0,0,0,.6);
    display: flex;
    align-items: center;
    cursor: text;
    pointer-events: none;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 400;
}

.focussed {
    background-color: white;
    height: 18px;
    transform-origin: top left;
    transform: translate(0, -18px) scale(1);
    transition: all .1s ease-in-out;
}

.focussed_small {
    background-color: white;
    height: 18px !important;
    transform-origin: top left;
    transform: translate(0, -18px) scale(1);
    transition: all .1s ease-in-out;
}

.focussed_text_area {
    background-color: white;
    height: 18px;
    transform-origin: top left;
    transform: translate(0, -13px) scale(1);
    transition: all .1s ease-in-out;
}

.float-container textarea::-webkit-scrollbar {
    width: 6px;
    background-color: transparent; }
  
.float-container textarea::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #4f4f4f; }

label[valid=false], label[valid=false], label[valid=false] {
    color: rgb(176, 11, 31);
}

.float-container[valid=false], .float-container[valid=false], .float-container[valid=false] {
    border: solid 1px rgb(176, 11, 31);
}

.float-container[disabled=true] {
    border: solid 1px #c3c3c3;
    color: #c3c3c3
}

.float-container input:disabled {
    color: rgba(0, 0, 0, 0.6);
    cursor: not-allowed;
}

.valid_box {
    display:none;
}

.invalid_message {
    margin-bottom: 12px;
    opacity: 1;
    color: rgb(176, 11, 31);
}

.labeled {
    width: 98%;
    resize: none;
    border: 1px solid #D5DAE0;
    border-radius: 4px;
    background-color: transparent;
    outline: none;
    font-size: 16px;
}

.labeled_textarea {
    resize: none;
    border: 1px solid #D5DAE0;
    border-radius: 4px;
    background-color: transparent;
    outline: none;
    padding: 15px;
    width: -webkit-fill-available;
}

.invalid_text_area {
    border-color: rgb(176, 11, 31);
}

textarea {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.009375em;
    text-decoration: inherit;
    text-transform: inherit;
    -webkit-font-smoothing: antialiased;
    font-display: block;
    height: 100%;
}

.character_counter {
    position: absolute;
    top: 0;
    right: 0;
    margin: 5px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    background-color: white;
    border-radius: 6px;
    padding: 5px;
    background-color: white;
    cursor: text;
    pointer-events: none;
    user-select: none;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 400;
}

textarea::-webkit-scrollbar {
    width: 6px;
    height: 8px;
    background-color: transparent; 
}
  
textarea::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #4f4f4f;
}
`;
