const mobileUserAgents = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
];

let isMobileBrowser;

export function isMobile() {
    if (isMobileBrowser == null) {
        isMobileBrowser = mobileUserAgents.some(item => navigator.userAgent.match(item));
    }

    return isMobileBrowser;
}

export function isLandscapeMobile() {
    return isMobile() && isLandscape();
}

export function isPortaitMobile() {
    return isMobile() && isLandscape() === false;
}

// Tablet
const tabletUserAgents = [
    /iPad/i,
    /Android/i,
];

let isTabletBrowser;

export function isTablet() {
    if (isTabletBrowser == null) {
        isTabletBrowser = tabletUserAgents.some(item => navigator.userAgent.match(item));
    }

    return isTabletBrowser;
}

export function isLandscapeTablet() {
    return isTablet() && isLandscape();
}

export function isPortaitTablet() {
    return isTablet() && isLandscape() === false;
}

// Common

export function isLandscape() {
    return window.matchMedia("(orientation: landscape)").matches
}

export function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}
