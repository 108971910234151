var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../styles/login.scss';
import { isMobile } from '@ui/common/js/utils/agent_checker';
let Login = class Login extends LitElement {
    constructor() {
        super();
        this.selectedForm = 'login_form';
    }
    createRenderRoot() {
        return this;
    }
    render() {
        const mobile = isMobile();
        return html `
            <div class="${mobile ? 'login_wrapper_mobile' : 'login_wrapper'}">
                <topbar-logged-out style="width: 100%;"></topbar-logged-out>
                <div class="${mobile ? 'modal_wrapper_mobile' : 'modal_wrapper'}">
                    ${mobile === false ? html `
                        <div>
                            <img alt="logo" src="/static/ui/common/css/images/MyAmigo_Logo.webp" height="45">
                        </div>
                    ` : html `
                        <div style="height: 20px;"></div>
                    `}
                    ${this.renderSelectedForm()}
                    ${this.renderFooterLinks()}
                </div>
            </div>
        `;
    }
    renderFooterLinks() {
        return html `
            <div class="${isMobile() ? 'diginova_links_mobile' : 'diginova_links'}">
                <div>
                    <a
                        href="https://diginova.nl"
                        rel="noopener noreferrer"
                        target="_blank"
                        style="text-decoration: none;"
                    >&copy; 2021 myAmigo</a>
                </div>
                <div class="footer_message">
                    <a
                        href="https://www.diginova.nl/privacy/"
                        rel="noopener noreferrer"
                        target="_blank"
                        style="text-decoration: none;"
                    >privacy policy</a>
                </div>
            </div>
        `;
    }
    renderSelectedForm() {
        if (this.selectedForm === 'login_form') {
            return html `
                <login-form
                    @formSelectionChanged="${(e) => this.selectedForm = e.detail.form}"
                ></login-form>`;
        }
        else if (this.selectedForm === 'forgot_password_form') {
            return html `
                <forgot-password-form
                    @back="${() => this.selectedForm = 'login_form'}"
                ></forgot-password-form>
            `;
        }
        else if (this.selectedForm === 'request_access_form') {
            return html `
                <request-access-form
                    @back="${() => this.selectedForm = 'login_form'}"
                ></request-access-form>
            `;
        }
    }
    firstUpdated() {
        window.localStorage.setItem('selectedLocation', '');
    }
};
__decorate([
    property()
], Login.prototype, "selectedForm", void 0);
Login = __decorate([
    customElement('dn-login')
], Login);
export default Login;
