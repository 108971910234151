var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { getTranslationByKey } from '@ui/common/js/data/translations_data';
import { isMobile } from '@ui/common/js/utils/agent_checker';
import debounce from 'lodash.debounce';
import './dropdown.scss';
let Dropdown = class Dropdown extends LitElement {
    constructor() {
        super();
        this.multi = false;
        this.items = [];
        this.displayItems = [];
        this.disabledItems = [];
        this.selectedItem = '';
        this.selectedIndex = -1;
        this.selectedItems = [];
        this.selectedIndices = [];
        this.filter = '';
        this.valid = true;
        this.invalidText = '';
        this.dropdownOpen = false;
        this.wrapperID = this.generateRandomID();
        this.bodyID = this.generateRandomID();
        this.filterID = this.generateRandomID();
        this.optionsID = this.generateRandomID();
        this.selectAllToggleTo = true;
        this.clickListener = (e) => {
            try {
                const dropdown = document.getElementById(this.optionsID);
                const dropdownOpener = document.getElementById(this.bodyID);
                if (this.dropdownOpen && !dropdown.contains(e.target) && !dropdownOpener.contains(e.target)) {
                    this.toggleDropdown(false);
                }
            }
            catch (e) {
                console.error(`Something went wrong in dropdown.clickListener. open:${this.dropdownOpen} options:${this.optionsID} body:${this.bodyID}`);
                console.error(e);
            }
        };
        this.filterOptions = debounce((value) => {
            this.filter = value;
        }, 200);
    }
    shouldUpdate(changedProperties) {
        if (changedProperties.has('selectedItem')) {
            this.selectedIndex = this.items.indexOf(this.selectedItem);
        }
        if (changedProperties.has('selectedItems')) {
            this.selectedIndices = this.selectedItems.map(item => this.items.indexOf(item));
        }
        return super.shouldUpdate(changedProperties);
    }
    createRenderRoot() {
        return this;
    }
    render() {
        return html `
            <div id="${this.wrapperID}" class="dropdown_wrapper">
                ${this.icon ? html `<dn-icon fontAwesomeIcon="${this.icon}"></dn-icon>` : ''}
                <div style="width: 100%;">
                    <div
                        id="${this.bodyID}"
                        class="dropdown_body ${this.valid ? '' : 'invalid_dropdown'} ${this.noBorder ? 'no_border' : ''}"
                        style="height:${this.small ? '37.5px;' : '56px;'} z-index: ${this.zIndex}; margin-left: ${this.icon ? '12px;' : '0;'}"
                        @click="${() => this.disabled ? '' : this.toggleDropdown(true)}"
                    >
                        ${this.placeholder !== '' ? html `
                            <label 
                                class="${this.calculateLabelClass()} ${this.valid === false ? 'invalid_dropdown_message' : ''}"
                                style="${this.labelColor && this.hasSelection() ? 'background-color: ' + this.labelColor + ';' : ''}"
                            >
                                <div>${this.placeholder}</div>
                            </label>
                        ` : ''}
                        ${this.fontAwesomeIcon ? html `<dn-icon fontAwesomeIcon="${this.fontAwesomeIcon}"></dn-icon>` : ''}
                        <div class="selected_item">${this.displaySelection()}</div>
                        <div class="amount_of_selections">${this.selectedItems.length}</div>
                        <dn-icon
                            style="padding-right: 15px; width: 20px;"
                            svg="${this.dropdownOpen ? 'caret_up' : 'caret_down'}"
                        ></dn-icon>
                    </div>
                    ${this.dropdownOpen ? html `
                    <div class="views_list_wrapper" style="margin-left: ${this.icon ? '12px;' : '0;'}">
                        ${this.renderDropdown()}
                    </div>
                    ` : ''}
                    ${this.valid === false ? html `
                        <div class="invalid_dropdown_message">${this.invalidText}</div>
                    ` : html ``}
                </div>
            </div>`;
    }
    displaySelection() {
        return this.multi ? '' : this.displayItemText(this.selectedIndex);
    }
    renderDropdown() {
        return html `
            <div
                id="${this.optionsID}"
                class="${isMobile() ? 'views_list_mobile' : 'views_list'}"
                style="top: 5px; max-height: ${isMobile() ? '12em;' : '20em;'}"
            >
                ${this.hasFilter ? html `
                    <div class="filter_input_wrapper">
                        <input
                            type="text"
                            class="filter_input"
                            placeholder="filter"
                            value="${this.filter}"
                            id="${this.filterID}"
                            @keyup="${(e) => this.filterOptions(e.target.value)}"
                        />
                        <div class="filter_icon">
                            <dn-icon fontAwesomeIcon="fas fa-search"></dn-icon>
                        </div>
                    </div>
                ` : ''}
                ${this.renderDropdownOptions()}
                ${this.multi && this.hasSelectAll ? html `
                    <dn-checkbox
                        class="select_all"
                        label="${getTranslationByKey('select_all')}"
                        variant="checkbox"
                        @selected="${(e) => this.selectAllItems()}"
                        ?checked=${!this.selectAllToggleTo}
                        ></dn-checkbox>
                ` : ''}
            </div>
        `;
    }
    renderDropdownOptions() {
        const allItems = this.displayItems.length > 0 ? this.displayItems : this.items;
        let filteredIndices = [];
        if (this.filter !== '') {
            for (let i = 0; i < allItems.length; i++) {
                const item = allItems[i];
                if (item.toLowerCase().includes(this.filter.toLowerCase())) {
                    filteredIndices.push(i);
                }
            }
        }
        else {
            filteredIndices = [...allItems.keys()];
        }
        if (filteredIndices.length === 0) {
            return html `
                <div class="disabled-list-item">
                    ${getTranslationByKey('dropdown_filter_no_results')}
                </div>`;
        }
        else {
            return filteredIndices.map(index => {
                return this.displayItem(index);
            });
        }
    }
    ;
    displayItem(index) {
        const isDisabled = this.disabledItems.find(disabledItem => disabledItem === this.items[index]);
        const displayText = this.displayItemText(index);
        if (this.multi) {
            return html `
                <dn-checkbox
                    label="${displayText}"
                    variant="checkbox"
                    @selected="${(e) => this.itemSelectedMulti(index)}"
                    ?checked=${this.isSelected(index)}
                    ?disabled=${isDisabled}
                    ></dn-checkbox>
            `;
        }
        else {
            return html `
                <div
                    class="${isDisabled ? 'disabled-list-item' : 'list-item'}"
                    @click="${() => isDisabled ? '' : this.itemSelected(index)}"
                >${displayText}</div>
            `;
        }
    }
    displayItemText(index) {
        return index === -1 ? this.selectedItem : // for backwards compatibility
            index < this.displayItems.length ? this.displayItems[index] : this.items[index];
    }
    calculateLabelClass() {
        let className = '';
        if (this.hasSelection()) {
            className += this.small ? 'focussed_dropdown_label_small' : 'focussed_dropdown_label';
        }
        return 'floating_dropdown_label ' + className;
    }
    hasSelection() {
        return this.selectedItem !== '' && this.selectedItem !== ' ';
    }
    connectedCallback() {
        super.connectedCallback();
        document.body.addEventListener('click', this.clickListener);
    }
    disconnectedCallback() {
        document.removeEventListener('click', this.clickListener);
        super.disconnectedCallback();
    }
    toggleDropdown(to) {
        this.dropdownOpen = (to !== undefined) ? to : !this.dropdownOpen;
    }
    itemSelected(index) {
        this.toggleDropdown(false);
        this.selectedItem = this.items[index];
        this.selectedIndex = index;
        this.filter = '';
        const element = document.getElementById(this.filterID);
        if (element) {
            element.value = '';
        }
        super.dispatchEvent(new CustomEvent('selected', {
            detail: {
                selection: this.selectedItem,
                selectedIndex: this.selectedIndex,
            }
        }));
    }
    isSelected(index) {
        return this.multi ? this.selectedIndices.includes(index) : index === this.selectedIndex;
    }
    itemSelectedMulti(index) {
        const i = this.selectedIndices.indexOf(index);
        const shouldRemove = i >= 0; // if it was previously selected
        if (shouldRemove) { // remove
            this.selectedIndices.splice(i, 1);
            this.selectedItems.splice(i, 1);
        }
        else { // add
            this.selectedIndices.push(index);
            this.selectedItems.push(this.items[index]);
        }
        super.dispatchEvent(new CustomEvent('selectionChanged', {
            detail: {
                selected: this.items[index],
                selectedIndex: index,
                removed: shouldRemove
            }
        }));
        this.requestUpdate();
    }
    selectAllItems() {
        if (this.selectAllToggleTo) {
            this.selectedItems = [...this.items];
            this.selectedIndices = [...this.items.keys()];
        }
        else {
            this.selectedItems = [];
            this.selectedIndices = [];
        }
        super.dispatchEvent(new CustomEvent('toggleAll', {
            detail: { selected: this.selectAllToggleTo }
        }));
        this.selectAllToggleTo = !this.selectAllToggleTo;
    }
    generateRandomID() {
        return Math.random().toString(36).substr(2, 9);
    }
};
__decorate([
    property({ type: Boolean })
], Dropdown.prototype, "multi", void 0);
__decorate([
    property({ type: String })
], Dropdown.prototype, "icon", void 0);
__decorate([
    property({ type: Object })
], Dropdown.prototype, "items", void 0);
__decorate([
    property({ type: Object })
], Dropdown.prototype, "displayItems", void 0);
__decorate([
    property({ type: Object })
], Dropdown.prototype, "disabledItems", void 0);
__decorate([
    property({ type: String })
], Dropdown.prototype, "selectedItem", void 0);
__decorate([
    property({ type: Number })
], Dropdown.prototype, "selectedIndex", void 0);
__decorate([
    property({ type: Array })
], Dropdown.prototype, "selectedItems", void 0);
__decorate([
    property({ type: Array })
], Dropdown.prototype, "selectedIndices", void 0);
__decorate([
    property({ type: Boolean })
], Dropdown.prototype, "hasSelectAll", void 0);
__decorate([
    property({ type: String })
], Dropdown.prototype, "placeholder", void 0);
__decorate([
    property({ type: String })
], Dropdown.prototype, "labelColor", void 0);
__decorate([
    property({ type: String })
], Dropdown.prototype, "filter", void 0);
__decorate([
    property({ type: Number })
], Dropdown.prototype, "zIndex", void 0);
__decorate([
    property({ type: Boolean })
], Dropdown.prototype, "valid", void 0);
__decorate([
    property({ type: String })
], Dropdown.prototype, "invalidText", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], Dropdown.prototype, "dropdownOpen", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], Dropdown.prototype, "small", void 0);
__decorate([
    property({ type: Boolean })
], Dropdown.prototype, "hasFilter", void 0);
__decorate([
    property({ type: Boolean })
], Dropdown.prototype, "noBorder", void 0);
__decorate([
    property({ type: Boolean })
], Dropdown.prototype, "disabled", void 0);
__decorate([
    property({ type: String })
], Dropdown.prototype, "fontAwesomeIcon", void 0);
Dropdown = __decorate([
    customElement('dn-dropdown')
], Dropdown);
export default Dropdown;
