import { css } from 'lit';
export const ButtonStyles = css `
:host {
    --button-border-radius: 2px;
    --button-padding: 0 1.5em;
    --button-mobile-padding: 0 0.5em;
    --button-tablet-padding: 0 1em;
}

:host([nopointerevents="true"]) .dn-button:active {
    pointer-events: none;
}

:host([ismobile="true"]) .dn-button {
    height:42px;
}

:host([ismobile="true"]) button {
    height: 42px;
    padding: 0 1em;
}

.dn-button {
    background: var(--button-primary);
}

.dn-button:hover {
    box-shadow: var(--button-primary-hover);
}

.dn-button dn-common-icon {
    --icon-color: var(--background);
}

.dn-button--secondary {
    background: var(--button-secondary-background);
    color: var(--button-secondary-color);
    border: var(--button-secondary-border);
}

.dn-button--secondary dn-common-icon {
    --icon-color: var(--background);
}

.dn-button--secondary:hover {
    box-shadow: var(--button-secondary-hover);
}

.dn-button--tertiary {
    background: var(--button-tertiary-background);
    color: var(--button-tertiary-color);
}

.dn-button--tertiary dn-common-icon {
    --icon-color: var(--background);
}

.dn-button--tertiary:hover {
    box-shadow: var(--button-tertiary-hover);
}

.dn-button--success {
    background: var(--button-success-background);
    color: var(--button-success-color);
}

.dn-button--success dn-common-icon {
    --icon-color: var(--background);
}

.dn-button--success:hover {
    box-shadow: var(--button-success-hover);
}

.dn-button--warning {
    background: var(--button-warning-background);
    color: var(--button-warning-color);
}

.dn-button--warning dn-common-icon {
    --icon-color: var(--background);
}

.dn-button--warning:hover {
    box-shadow: var(--button-warning-hover);
}

.dn-button--discard {
    background: var(--button-discard-background);
    color: var(--button-discard-color);
}

.dn-button--discard dn-common-icon {
    --icon-color: var(--background);
}


.dn-button--discard:hover {
    box-shadow: var(--button-discard-hover);
}

.dn-button--grey {
    background: var(--button-grey-background);
    color: var(--button-grey-color);
    border: var(--button-grey-border);
}


.dn-button--discard dn-common-icon {
    --icon-color: var(--text-color);
}


.dn-button--grey:hover {
    box-shadow: var(--button-grey-hover);
}

.base-button {
    height: 36px;
    width: 100%;
    line-height: 36px;
    display: block;
    padding: var(--button-padding);
    border: 0;
    border-radius: var(--button-border-radius);
    text-decoration: none;
    color: #fff;
    transition: background 0.25s ease-in-out;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
}

:host([ismobile="true"]) .base-button {
    padding: var(--button-mobile-padding);
}

:host([istablet="true"]) .base-button {
    padding: var(--button-tablet-padding);
}

.base-button:focus {
    outline: 0;
}

.base-button:disabled {
    opacity:0.3;
    cursor: default;
}
  
[data-animation] {
    position: relative;
    overflow: hidden;
}

.highlight {
  height: 42px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  top: 0px;
  position: absolute;
  left: 0;
}

.ripple {
    width: 2px;
    height: 2px;
    position: absolute;
    border-radius: 50%;
    box-shadow: 50px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 1);
    -webkit-animation: rippleEffect 0.5s;
            animation: rippleEffect 0.5s;
}

@-webkit-keyframes rippleEffect {
    0% {
        opacity: 0.1;
        -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
        opacity: 0.6;
        -webkit-transform: scale(var(--scale));
              transform: scale(var(--scale));
    }
}

@keyframes rippleEffect {
    0% {
        opacity: 0.1;
        -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
        opacity: 0.6;
        -webkit-transform: scale(var(--scale));
              transform: scale(var(--scale));
    }
}

`;
