var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { getTranslationByKey } from '@ui/common/js/data/translations_data';
import { setState, getObservable } from '../data/login_data';
import { SetStateMessages, GetObservableMessages } from '../models/models';
import { isMobile } from '@ui/common/js/utils/agent_checker';
let ForgotPasswordForm = class ForgotPasswordForm extends LitElement {
    constructor() {
        super();
        this.emailValid = true;
        this.email = '';
        document.getElementById('body2').addEventListener('language_selected', () => {
            super.requestUpdate();
        });
    }
    createRenderRoot() {
        return this;
    }
    render() {
        return html `
            <div class="${isMobile() ? 'modal_mobile' : 'modal'}">
                <div class="login_modal_header">
                    <div class="modal_title">
                        <dn-icon
                            style="cursor: pointer;"
                            fontawesomeIcon="fa-arrow-left"
                            @click="${() => this.backToLoginForm()}"
                            color="#2160F4"
                        ></dn-icon>
                        <h2 style="margin-left: 1em;">${getTranslationByKey('forgot_password_title')}</h2>
                    </div>
                    <div class="modal_subtitle">
                        ${getTranslationByKey('forgot_password_subtitle')}
                    </div>
                </div>
                <div>
                    <dn-input-field
                        label="${getTranslationByKey('email_input')}"
                        @change="${(e) => this.email = e.detail.value}"
                        ?valid="${this.emailValid === true}"
                        invalidText="${getTranslationByKey('email_invalid')}"
                    ></dn-input-field>
                    <dn-button
                        text="${getTranslationByKey('reset_password_button')}"
                        @click="${() => this.requestNewPassword()}"
                    ></dn-button>
                    <div style="height: 2em;"></div>
                    ${this.requestInfo ? html `
                        <div class="${this.requestInfo.success ? 'request_access_success' : 'request_access_failed'}">
                            ${getTranslationByKey(this.requestInfo?.info)}
                        </div>
                    ` : ''}
                </div>
            </div>
        `;
    }
    backToLoginForm() {
        super.dispatchEvent(new CustomEvent('back'));
    }
    requestNewPassword() {
        this.emailValid = setState({
            type: SetStateMessages.REQUEST_NEW_PASSWORD,
            data: this.email,
        });
    }
    firstUpdated() {
        getObservable(GetObservableMessages.REQUEST_NEW_PASSWORD_INFORMATION).subscribe(info => {
            this.requestInfo = info;
        });
    }
};
__decorate([
    property({ type: String })
], ForgotPasswordForm.prototype, "emailValid", void 0);
__decorate([
    property({ type: Object })
], ForgotPasswordForm.prototype, "requestInfo", void 0);
ForgotPasswordForm = __decorate([
    customElement('forgot-password-form')
], ForgotPasswordForm);
export default ForgotPasswordForm;
