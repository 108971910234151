var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { styles } from './input_field_styles';
export var Variant;
(function (Variant) {
    Variant["formfield"] = "formfield";
    Variant["numberfield"] = "numberfield";
    Variant["password"] = "password";
})(Variant || (Variant = {}));
let InputField = class InputField extends LitElement {
    constructor() {
        super();
        this.label = 'type here';
        this.variant = 'formfield';
        this.text = '';
        this.invalidText = '';
        this.id = this.generateRandomID();
    }
    static get styles() {
        return styles;
    }
    render() {
        return html `
            <div
                class="float-container ${this.noBorder ? 'no_border' : ''}"
                style="${this.width ? 'width: ' + this.width + ';' : ''} ${this.small ? 'height: 42px;' : ''}"
                valid="${this.valid === true ? 'true' : 'false'}"
                disabled="${this.disabled === "true"}"
            >
                <label
                    for="${this.id}"
                    valid="${this.valid === true ? 'true' : 'false'}"
                    disabled="${this.disabled === "true"}"
                    class="input_label ${this.hasFocus || this.text !== '' && this.text !== ' ' ? this.small ? 'focussed_small' : 'focussed' : ''}"
                    style="${this.labelColor && this.hasFocus || this.text !== '' && this.text !== ' ' ? 'background-color: ' + this.labelColor + ';' : ''} ${this.small ? 'height: 28px;' : ''}"
                >
                    ${this.label[0]?.toUpperCase() + this.label?.substr(1)}
                </label>
                <input
                    id="${this.id}"
                    type="${this.calculateType()}"
                    class="labeled"
                    style="${this.small ? 'height: 23px; padding-top: 3px;' : ''}"
                    .value="${this.text}"
                    ?disabled="${this.disabled}"
                    @focus="${() => this.inputFocussed()}"
                    @blur="${() => this.focusLost()}"
                    @change="${(e) => this.inputChanged(e.target.value)}"
                    @keyup="${(e) => this.keyUp(e.target.value)}"
                    autocomplete="${this.stopAutoFill ? 'new-password' : ''}"
                    maxlength="${this.maxLength ? this.maxLength : ''};"
                    min="0"
                />
            </div>
            ${this.valid ? html `
                <div class="valid_box"></div>
            ` : html `
                <div class="invalid_message">${this.invalidText}</div>
            `}
        `;
    }
    calculateType() {
        if (this.variant === Variant.password) {
            return 'password';
        }
        else if (this.variant === Variant.numberfield) {
            return 'number';
        }
        else {
            return 'text';
        }
    }
    inputFocussed() {
        this.hasFocus = true;
    }
    focusLost() {
        this.hasFocus = null;
    }
    inputChanged(value) {
        if (this.variant === Variant.numberfield) {
            value = Number(value);
        }
        else {
            value = value.trim();
        }
        this.text = value;
        super.dispatchEvent(new CustomEvent('change', {
            detail: {
                value: value,
            }
        }));
    }
    keyUp(value) {
        if (this.variant === Variant.numberfield) {
            value = Number(value);
        }
        else {
            value = value.trim();
        }
        super.dispatchEvent(new CustomEvent('keypressed', {
            detail: {
                value: value || '',
            }
        }));
    }
    generateRandomID() {
        return Math.random().toString(36).substr(2, 9);
    }
};
__decorate([
    property({ type: String })
], InputField.prototype, "label", void 0);
__decorate([
    property({ type: String })
], InputField.prototype, "variant", void 0);
__decorate([
    property({ type: String })
], InputField.prototype, "text", void 0);
__decorate([
    property({ type: Boolean })
], InputField.prototype, "valid", void 0);
__decorate([
    property({ type: String })
], InputField.prototype, "invalidText", void 0);
__decorate([
    property({ type: Boolean })
], InputField.prototype, "disabled", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], InputField.prototype, "hasFocus", void 0);
__decorate([
    property({ type: String })
], InputField.prototype, "width", void 0);
__decorate([
    property({ type: Boolean })
], InputField.prototype, "small", void 0);
__decorate([
    property({ type: Boolean })
], InputField.prototype, "stopAutoFill", void 0);
__decorate([
    property({ type: String })
], InputField.prototype, "labelColor", void 0);
__decorate([
    property({ type: Boolean })
], InputField.prototype, "noBorder", void 0);
__decorate([
    property({ type: Number })
], InputField.prototype, "maxLength", void 0);
InputField = __decorate([
    customElement('dn-input-field')
], InputField);
export default InputField;
