var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { viewport } from '@ui/common/js/viewport/detection';
import { LitElement } from 'lit';
import { property } from 'lit/decorators.js';
export class BaseElement extends LitElement {
    constructor() {
        super();
        this.isMobile = viewport.isMobile;
        this.isMobileWidth = viewport.isMobileWidth;
        this.isTablet = viewport.isTablet;
        this.isTabletWidth = viewport.isTabletWidth;
        this.isDesktop = viewport.isDesktop;
        this.isDesktopWidth = viewport.isDesktopWidth;
        this.isLandscape = viewport.isLandscape;
        this.isPortrait = viewport.isPortrait;
        this.translateElement = this.translateElement.bind(this);
        this.resizeElement = this.resizeElement.bind(this);
    }
    translateElement() {
        super.requestUpdate();
    }
    resizeElement() {
        // Make sure that on resize, the isMobile, isTablet and isDesktop are using window sizes...
        this.isMobile = viewport.isMobileWidth;
        this.isMobileWidth = viewport.isMobileWidth;
        this.isTablet = viewport.isTabletWidth;
        this.isTabletWidth = viewport.isTabletWidth;
        this.isDesktop = viewport.isDesktopWidth;
        this.isDesktopWidth = viewport.isDesktopWidth;
        this.isLandscape = viewport.isLandscape;
        this.isPortrait = viewport.isPortrait;
        super.requestUpdate();
    }
    async scheduleUpdate() {
        await new Promise(res => setTimeout(res));
        super.scheduleUpdate();
    }
    firstUpdated() {
        viewport.isReady.subscribe((ready) => {
            if (ready) {
                this.isMobile = viewport.isMobile;
                this.isTablet = viewport.isTablet;
                this.isDesktop = viewport.isDesktop;
                this.isLandscape = viewport.isLandscape;
                this.isPortrait = viewport.isPortrait;
            }
        });
        viewport.isResized.subscribe((ready) => {
            if (ready) {
                this.isMobile = viewport.isMobileWidth;
                this.isTablet = viewport.isTabletWidth;
                this.isDesktop = viewport.isDesktopWidth;
                this.isLandscape = viewport.isLandscape;
                this.isPortrait = viewport.isPortrait;
            }
        });
    }
    connectedCallback() {
        super.connectedCallback();
        document.getElementById('body2').addEventListener('translate', this.translateElement);
        window.addEventListener('translations_ready', this.translateElement);
        window.addEventListener('resize', this.resizeElement);
    }
    disconnectedCallback() {
        document.getElementById('body2').removeEventListener('translate', this.translateElement);
        window.removeEventListener('translations_ready', this.translateElement);
        window.removeEventListener('resize', this.resizeElement);
        super.disconnectedCallback();
    }
}
__decorate([
    property({ reflect: true })
], BaseElement.prototype, "isMobile", void 0);
__decorate([
    property({ reflect: true })
], BaseElement.prototype, "isMobileWidth", void 0);
__decorate([
    property({ reflect: true })
], BaseElement.prototype, "isTablet", void 0);
__decorate([
    property({ reflect: true })
], BaseElement.prototype, "isTabletWidth", void 0);
__decorate([
    property({ reflect: true })
], BaseElement.prototype, "isDesktop", void 0);
__decorate([
    property({ reflect: true })
], BaseElement.prototype, "isDesktopWidth", void 0);
__decorate([
    property({ reflect: true })
], BaseElement.prototype, "isLandscape", void 0);
__decorate([
    property({ reflect: true })
], BaseElement.prototype, "isPortrait", void 0);
