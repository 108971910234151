export var SetStateMessages;
(function (SetStateMessages) {
    SetStateMessages["LOGIN"] = "login";
    SetStateMessages["REQUEST_NEW_PASSWORD"] = "request_new_password";
    SetStateMessages["REQUEST_ACCESS"] = "request_access";
})(SetStateMessages || (SetStateMessages = {}));
;
export var GetObservableMessages;
(function (GetObservableMessages) {
    GetObservableMessages["FAILED_LOGIN_INFORMATION"] = "failed_login_information";
    GetObservableMessages["REQUEST_NEW_PASSWORD_INFORMATION"] = "request_new_password_information";
    GetObservableMessages["REQUEST_ACCESS_INFORMATION"] = "request_access_information";
    GetObservableMessages["LOCATION_OPTIONS"] = "location_options";
})(GetObservableMessages || (GetObservableMessages = {}));
;
