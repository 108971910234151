import { css } from 'lit';
export const styles = css `
    :host([backdrop]) .message {
        display: block;
        position: relative;
        transform: translate(0%, 30%);
        width: 100dvw;
        height: 110dvh;
        backdrop-filter: blur(5px);
        background: rgba(0, 0, 0, 0.7);
    }

    .message .wrapper {
        border-radius: 5px;
        min-height: 250px;
        min-width: 600px;
        width: auto;
        width: inherit;
        float: unset;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        padding: 10px;
    }

    :host([backdrop]) .message .wrapper {
        border-radius: 5px;
        min-height: 250px;
        min-width: 600px;
        width:auto;
        position: fixed;
        top: 15%;
        transform: translateX(-50%);
        left: 50%;
        float: unset;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        padding: 10px;
    }

    .wrapper {
        border-radius: 40px;
        display: flex;
        width: fit-content;
        float: left;
    }

    

    .message .wrapper:before {
        content: '';
        position:absolute;
        top: 4px;
        left:4px;
        right:4px;
        bottom:4px;
        background: rgba(255,255,255,0.3);
    }

    .message .toast_header {
        font-weight:bold;
    }

    .toast .toast_header {
        height: 2em;
        width: 3em;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;
        color: white;
    }

    .toast_body {
        height: fit-content;
        justify-content: space-around;
        align-items: center;
        display: flex;
        padding: 1em;
        padding-right: 6px;
        background-color: var(--background);
        border-top-right-radius: 40px;
        border-bottom-right-radius: 40px;
    }

    .message .toast_body {
        background: transparent;
    }

    .close_button {
        width: 2em;
        height: 100%;
        color: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-weight: 800;
    }

    .primary {
        background: var(--primary-color);
    }

    .message .primary {
        color: var(--background);
    }

    .toast .primary {
        border: 1px solid var(--primary-color);
    }

    .secondary {
        background: var(--secondary-color);
    }
    
    .message .secondary {
        color: var(--background);
    }

    .toast .secondary {
        border: 1px solid var(--secondary-color);
    }

    .tertiary {
        background: var(--tertiary-color);
    }

    .message .tertiary {
        color: var(--background);
    }

    .toast .tertiary {
        border: 1px solid var(--tertiary-color);
    }

    .success {
        background: var(--success-color);
    }
    
    .message .success {
        color: var(--background);
    }

    .toast .success {
        border: 1px solid var(--success-color);
    }

    .warning {
        background: var(--yellow-color);
    }

    .toast .warning {
        border: 1px solid var(--yellow-color);
    }

    .danger {
        background: var(--danger-color);
    }

    .message .danger {
        color: var(--background);
    }

    .toast .danger {
        border: 1px solid var(--danger-color);
    }

    .grey {
        background: var(--grey-color);
    }

    .toast .grey {
        border: 1px solid var(--grey-color);
    }
`;
