var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { property, customElement, state } from 'lit/decorators.js';
import { styles } from './notification_styles';
import { Variant as ButtonVariant } from '@ui/common/js/base_components/button/button';
var Type;
(function (Type) {
    Type["TOAST"] = "toast";
    Type["MESSAGE"] = "message";
})(Type || (Type = {}));
;
var Color;
(function (Color) {
    Color["primary"] = "primary";
    Color["secondary"] = "secondary";
    Color["tertiary"] = "tertiary";
    Color["success"] = "success";
    Color["warning"] = "warning";
    Color["discard"] = "danger";
    Color["grey"] = "grey";
})(Color || (Color = {}));
let Notification = class Notification extends LitElement {
    constructor() {
        super();
        this.type = Type.TOAST;
        this.color = Color.success;
        this.title = '';
        this.text = '';
        this.toastOverwriteRules = {
            backgroundColor: '',
            textColor: '',
            font: '',
        };
        this.backdrop = false;
        this.buttonVariant = ButtonVariant.success;
    }
    static get styles() {
        return styles;
    }
    render() {
        if (this.type === Type.TOAST) {
            return html `
            <div id="${this.id}" class="toast">
                <div class="wrapper ${this.color}">
                    <div class="toast_header">
                        <div>${this.title}</div>
                    </div>
                    <div class="toast_body">
                        <div>${this.text}</div>
                        <div
                            class="close_button"
                            @click="${() => this.close()}"
                        >x</div>
                    </div>
                </div>
            </div>
        `;
        }
        else if (this.type === Type.MESSAGE) {
            return html `
            <div id="${this.id}" class="message">
                <div class="wrapper ${this.color}">
                    <div class="toast_header">
                        <div>${this.title}</div>
                    </div>
                    <div class="toast_body">
                        <div>${this.text}</div>
                    </div>
                    <div class="toast_footer">
                        <dn-common-button variant="${this.buttonVariant}" id="confirm" @click="${this.close}" text="Ok"></dn-common-button>
                    </div>
                </div>
            </div>
        `;
        }
    }
    updated(changedProperties) {
        if (changedProperties.has('color')) {
            this.setColor();
        }
    }
    setColor() {
        switch (this.color) {
            case Color.primary:
                this.buttonVariant = ButtonVariant.primary;
                break;
            case Color.secondary:
                this.buttonVariant = ButtonVariant.secondary;
                break;
            case Color.tertiary:
                this.buttonVariant = ButtonVariant.tertiary;
                break;
            case Color.success:
                this.buttonVariant = ButtonVariant.success;
                break;
            case Color.warning:
                this.buttonVariant = ButtonVariant.warning;
                break;
            case Color.discard:
                this.buttonVariant = ButtonVariant.discard;
                break;
            case Color.grey:
                this.buttonVariant = ButtonVariant.grey;
                break;
        }
    }
    firstUpdated() {
        this.setColor();
    }
    close() {
        const hostElement = this.shadowRoot.host;
        hostElement.remove();
    }
};
__decorate([
    property({ type: String })
], Notification.prototype, "id", void 0);
__decorate([
    property({ type: String })
], Notification.prototype, "type", void 0);
__decorate([
    property({ type: String })
], Notification.prototype, "color", void 0);
__decorate([
    property({ type: String })
], Notification.prototype, "title", void 0);
__decorate([
    property({ type: String })
], Notification.prototype, "text", void 0);
__decorate([
    property({ type: Object })
], Notification.prototype, "toastOverwriteRules", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], Notification.prototype, "backdrop", void 0);
__decorate([
    state()
], Notification.prototype, "buttonVariant", void 0);
Notification = __decorate([
    customElement('dn-notification')
], Notification);
export default Notification;
