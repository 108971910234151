export function login(credentials) {
    const url = window.location.href;
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        credentials: "same-origin",
        body: new URLSearchParams(credentials),
    }).then(result => {
        return result;
    });
}
export function requestNewAccount(email, location) {
    const url = window.location.protocol + '//' + window.location.host + '/ui/loginui/request_login/';
    const data = location != null ? {
        email: email,
        location: location,
    } : {
        email: email,
    };
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: new URLSearchParams(data),
    }).then(result => {
        return result.json();
    });
}
export function requestNewPassword(email) {
    const url = window.location.protocol + '//' + window.location.host + '/ui/loginui/forgot_password/';
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: new URLSearchParams({
            email: email,
            url: location.href,
        }),
    }).then(result => {
        return result.json();
    });
}
export function resetPassword(email, password) {
    const token = window.location.href.split('/')[6];
    const url = window.location.protocol + '//' + window.location.host + '/ui/loginui/password_reset/' + token + '/';
    return fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: new URLSearchParams({
            email: email,
            password: password,
        }),
    }).then(result => {
        return result.json();
    });
}
