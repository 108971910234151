var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { setSelectedLanguage, getTranslationByKey } from '@ui/common/js/data/translations_data';
import { getRequestedPage } from '@ui/common/js/data/requested-page';
import debounce from 'lodash.debounce';
import { isMobile } from '@ui/common/js/utils/agent_checker';
import '../../styles/topbar_logged_out.scss';
let TopbarLoggedOut = class TopbarLoggedOut extends LitElement {
    constructor() {
        super();
        this.flagDropdownOpen = false;
        this.languages = [{
                icon: '/static/ui/common/css/images/netherlands_64.webp',
                language: 'nl',
            }, {
                icon: '/static/ui/common/css/images/united-kingdom_64.webp',
                language: 'en',
            }];
        this.selectedLanguage = this.languages[0];
        this.mobileNavOpen = false;
        this.toggleFlagDropdown = debounce(() => {
            this.flagDropdownOpen = !this.flagDropdownOpen;
        }, 50);
        this.clickListener = (e) => {
            const flagDropdown = document.getElementById('flag_dropdown');
            if (flagDropdown && this.flagDropdownOpen === true && flagDropdown.contains(e.target) === false) {
                if (this.flagDropdownOpen === true && document.getElementById('language').contains(e.target) === true) {
                    // chill
                }
                else {
                    this.flagDropdownOpen = false;
                }
            }
            const navbarDropdown = document.getElementById('mobile_nav_dropdown');
            if (isMobile() && navbarDropdown && this.mobileNavOpen === true && navbarDropdown.contains(e.target) === false) {
                if (this.mobileNavOpen === true && document.getElementById('hamburger').contains(e.target) === true) {
                    // chill
                }
                else {
                    this.mobileNavOpen = false;
                }
            }
        };
    }
    createRenderRoot() {
        return this;
    }
    render() {
        this.page = getRequestedPage();
        return isMobile() ? this.renderMobileTopbar() : this.renderTopbar();
    }
    renderTopbar() {
        return html `
        <div class="topbar_logged_out">
            ${this.page.includes('support') === false ? html `
                <dn-button
                    text="${getTranslationByKey('contact_support_title')}"
                    @click="${() => this.visitSupportForm()}"
                    style="margin-top: 26px; margin-right: 2em;"
                ></dn-button>
            ` : html `<div></div>`}
            ${this.page.includes('login') === false ? html `
                <dn-button
                    text="${getTranslationByKey('login_button')}"
                    @click="${() => this.visitLoginForm()}"
                    style="margin-top: 26px; margin-right: 2em;"
                ></dn-button>
            ` : html `<div></div>`}
            <div class="selected_language_wrapper" id="language" @click="${() => this.toggleFlagDropdown()}">
                <div style="display: flex; align-items: center;">
                    <div class="flag">
                        <img src="${this.selectedLanguage.icon}" height="50px;";/>
                    </div>
                    <i 
                        class="fas ${this.flagDropdownOpen ? 'fa-caret-up' : 'fa-caret-down'}"
                        style="margin-left: 1em;"
                    ></i>
                    ${this.flagDropdownOpen ? this.renderLanguageDropdown() : ''}
                </div>
            </div>
        </div>
    `;
    }
    renderLanguageDropdown() {
        return html `
            <div class="flag_dropdown" id="flag_dropdown">
                <div class="language_dropdown_wrapper">
                    <ul class="language_options">
                        ${this.languages.map(lang => html `
                            <li
                                class="option"
                                @click="${() => this.setLanguage(lang)}"
                                style="${lang.language === this.selectedLanguage.language ? 'opacity: 0.5;' : 'opacity: 1;'}"
                            >
                                <img height="50" width="50" src="${lang.icon}">
                            </li>
                        `)}
                    </ul>
                </div>
            </div>
        `;
    }
    renderMobileTopbar() {
        return html `
            <div class="login_header_mobile">
                <img alt="logo" src="/static/ui/common/css/images/MyAmigo_Logo.webp" height="45" class="logo">
                <dn-icon
                    id="hamburger"
                    class="hamburger"
                    fontAwesomeIcon="fas fa-bars"
                    @click="${() => this.toggleMobileNavbar()}"
                ></dn-icon>
            </div>
            <div class="${this.mobileNavOpen ? 'mobile_nav_wrapper' : 'hidden'}">
                <div class="${this.mobileNavOpen ? '' : 'hidden'} mobile_nav_dropdown" id="mobile_nav_dropdown">
                    <div class="login_header_mobile">
                        <img alt="logo" src="/static/ui/common/css/images/MyAmigo_Logo.webp" height="45" class="logo">
                        <dn-icon
                            id="hamburger"
                            class="hamburger"
                            fontAwesomeIcon="fas fa-times"
                            @click="${() => this.mobileNavOpen = false}"
                        ></dn-icon>
                    </div>
                    <div class="mobile_nav_buttons_wrapper">
                        ${this.page.includes('support') === false ? html `
                            <dn-button
                                text="${getTranslationByKey('contact_support_title')}"
                                @click="${() => this.visitSupportForm()}"
                            ></dn-button>
                        ` : ''}
                        ${this.page.includes('login') === false ? html `
                            <dn-button
                                text="${getTranslationByKey('login_button')}"
                                @click="${() => this.visitLoginForm()}"
                            ></dn-button>
                        ` : ''}
                    </div>
                    ${this.renderLanguageMobile()}
                </div>
            </div>
        `;
    }
    renderLanguageMobile() {
        return html `
            <div class="mobile_language_selector">
                <div
                    class="language_wrapper"
                    @click="${() => this.setLanguage(this.languages[0])}"
                >
                    <img
                        height="${this.selectedLanguage.language === 'nl' ? '18' : '16'}"
                        width="${this.selectedLanguage.language === 'nl' ? '18' : '16'}"
                        src="${this.languages[0].icon}"
                        class="${this.selectedLanguage.language === 'nl' ? '' : 'unselected_language'}"
                    >
                </div>
                <div
                    class="language_wrapper"
                    style="margin-left: 1em;"
                    @click="${() => this.setLanguage(this.languages[1])}"
                >
                    <img
                    height="${this.selectedLanguage.language === 'en' ? '18' : '16'}"
                        width="${this.selectedLanguage.language === 'en' ? '18' : '16'}"
                        src="${this.languages[1].icon}"
                        class="${this.selectedLanguage.language === 'en' ? '' : 'unselected_language'}"
                    >
                </div>
            </div>
        `;
    }
    toggleMobileNavbar() {
        this.mobileNavOpen = !this.mobileNavOpen;
    }
    setLanguage(lang) {
        setSelectedLanguage(lang.language);
        this.selectedLanguage = this.languages.find(language => language.language === lang.language);
        document.getElementById('body2').dispatchEvent(new CustomEvent('language_selected'));
        super.requestUpdate();
        setTimeout(() => {
            this.mobileNavOpen = false;
        }, 200);
    }
    visitSupportForm() {
        // @ts-ignore
        window.location = window.location.protocol + '//' + window.location.host + '/ui/common/support/';
    }
    visitLoginForm() {
        // @ts-ignore
        window.location = window.location.protocol + '//' + window.location.host + '/ui/loginui/login/';
    }
    connectedCallback() {
        super.connectedCallback();
        document.body.addEventListener('click', this.clickListener);
    }
    disconnectedCallback() {
        document.removeEventListener('click', this.clickListener);
        super.disconnectedCallback();
    }
    ripple(e) {
        const ripple = document.createElement('span');
        ripple.className = 'ripple';
        ripple.style.left = '87px';
        ripple.style.top = '-47px';
        ripple.style.setProperty('--scale', '348');
        e.target.appendChild(ripple);
    }
};
__decorate([
    property({ type: Boolean })
], TopbarLoggedOut.prototype, "flagDropdownOpen", void 0);
__decorate([
    property({ type: Object })
], TopbarLoggedOut.prototype, "selectedLanguage", void 0);
__decorate([
    property()
], TopbarLoggedOut.prototype, "mobileNavOpen", void 0);
TopbarLoggedOut = __decorate([
    customElement('topbar-logged-out')
], TopbarLoggedOut);
export default TopbarLoggedOut;
