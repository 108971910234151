var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import './icon.scss';
import { icons } from './iconset/iconset';
let Icon = class Icon extends LitElement {
    constructor() {
        super();
        this.fontawesomeIcon = '';
        this.svg = '';
        this.selected = false;
        this.variant = 'fas';
        this.primary = false;
    }
    createRenderRoot() {
        return this;
    }
    render() {
        return this.fontawesomeIcon ? html `
            <div class="${this.selected ? 'dn-icon--selected' : 'dn-icon'} ${this.primary ? 'primary_icon' : ''}">
                <i
                    class="${this.variant} ${this.fontawesomeIcon} fa-lg fa-fw"
                    style="${this.fontSize ? 'font-size: ' + this.fontSize + 'px;' : ''} ${this.color ? 'color:' + this.color + ';' : ''} ${this.isSmall ? 'width: 20px;' : ''} background-color: transparent;"
                ></i>
            </div>
        ` : html `
            <div class="${this.selected ? 'dn-icon--selected' : 'dn-icon'} ${this.primary ? 'primary_icon' : ''}">
                <i
                    class="${this.variant}"
                    style="${this.fontSize ? 'font-size: ' + this.fontSize + 'px;' : ''} ${this.color ? 'color:' + this.color + ';' : ''} ${this.isSmall ? 'width: 20px;' : ''} background-color: transparent;"
                >${icons[this.svg]}</i>
            </div>
        `;
    }
};
__decorate([
    property({ type: String })
], Icon.prototype, "fontawesomeIcon", void 0);
__decorate([
    property({ type: String })
], Icon.prototype, "svg", void 0);
__decorate([
    property({ type: Boolean })
], Icon.prototype, "selected", void 0);
__decorate([
    property({ type: String })
], Icon.prototype, "color", void 0);
__decorate([
    property({ type: Boolean })
], Icon.prototype, "isSmall", void 0);
__decorate([
    property({ type: Number })
], Icon.prototype, "fontSize", void 0);
__decorate([
    property({ type: String })
], Icon.prototype, "variant", void 0);
__decorate([
    property({ type: Boolean })
], Icon.prototype, "primary", void 0);
Icon = __decorate([
    customElement('dn-icon')
], Icon);
export default Icon;
