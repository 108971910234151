let translations = {};
const emptyTranslation = (key) => {
    return {
        Key: key,
        en: '',
        nl: '',
        fr: '',
    };
}

export function setTranslations(new_translations) {
    translations = new_translations;
    if (hasTranslations()) sessionStorage.setItem('translations', JSON.stringify(translations));
    window.dispatchEvent(
        new CustomEvent('translations_ready')
    );
}

export function getTranslations() {
    return translations;
}

export function setSelectedLanguage(language) {
    sessionStorage.setItem('selectedLanguage', language ?? '');
}

let selected_language; // for some reason this needs to be outside the function (presumably to fool the typechecker)
export function getSelectedLanguage() {
    const lang = sessionStorage.getItem('selectedLanguage');
    if (lang === 'undefined') {
        sessionStorage.setItem('selectedLanguage', '');
    } else if (lang) {
        selected_language = lang;
    }
    return selected_language;
}

function hasTranslation(key) {
    return key in translations && getSelectedLanguage() in translations[key];
}

function hasTranslations() {
    for (let _i in translations) return true;
    return false;
}

export function getTranslationByKey(key, fallback=null) {
    try {
        return translations[key][getSelectedLanguage()];
    } catch {
        if (!hasTranslations()) {
            let new_translations = sessionStorage.getItem('translations');
            if (new_translations && new_translations !== 'undefined') {
                setTranslations(JSON.parse(new_translations));
                return getTranslationByKey(key, fallback);
            }
        }

        // EWL 2022-03-25: teruggeven key als translatie niet bestaat
        return fallback === null ? '#' + key + '#' : fallback;
    }
}

export function getTranslationOfLocation(location, client) {
    var key = getLocationTranslationKey(location, client);
    return getTranslationByKey(key);
    // return hasTranslation(key) ?
    //     getTranslationByKey(key) :
    //     location;
}

export function getLocationTranslationKey(location, client) {
    return 'location_' + client.toLowerCase() + '_' + location.toLowerCase();
}

export function getTranslationObjectByKey(key) {
    return translations[key] || emptyTranslation(key);
}

function strcmp(a, b) {
    return a < b ? -1 : a > b ? 1 : 0;
}

export function sortLocationsByTranslation(locations, client) {
    return locations.toSorted((a,b) => strcmp(getTranslationOfLocation(a, client), getTranslationOfLocation(b, client)))
}