import { viewport } from '@ui/common/js/viewport/detection';
import './notifications.scss';
import { isMobile } from '@ui/common/js/utils/agent_checker';
import { Variant } from '@ui/common/js/base_components/button/button';
export var ToastPosition;
(function (ToastPosition) {
    ToastPosition["BOTTOMRIGHT"] = "bottom_right";
    ToastPosition["BOTTOMLEFT"] = "bottom_left";
    ToastPosition["BOTTOMCENTER"] = "bottom_center";
    ToastPosition["MIDDLE"] = "middle";
    ToastPosition["TOP"] = "top";
})(ToastPosition || (ToastPosition = {}));
;
export var ToastColor;
(function (ToastColor) {
    ToastColor["PRIMARY"] = "primary";
    ToastColor["SECONDARY"] = "secondary";
    ToastColor["TERTIARY"] = "tertiary";
    ToastColor["SUCCESS"] = "success";
    ToastColor["WARNING"] = "warning";
    ToastColor["DANGER"] = "danger";
    ToastColor["GREY"] = "grey";
})(ToastColor || (ToastColor = {}));
export var ToastType;
(function (ToastType) {
    ToastType["TOAST"] = "toast";
    ToastType["MESSAGE"] = "message";
    ToastType["LOGOUT"] = "logout";
})(ToastType || (ToastType = {}));
;
;
;
export const defaultRules = {
    backgroundColor: '',
    textColor: '',
    font: '',
};
export default class ToastService {
    constructor(rules) {
        this.toastOverwriteRules = defaultRules;
        this.toastOverwriteRules = rules;
        const floatingUI = document.getElementById('floating-ui') || document.body;
        if (document.getElementById('top_toast_wrapper') == null) {
            this.topWrapper = document.createElement('div');
            this.topWrapper.className = 'toasts_wrapper_center toasts_wrapper_top toast';
            this.topWrapper.id = 'top_toast_wrapper';
            floatingUI.appendChild(this.topWrapper);
        }
        if (document.getElementById('middle_toast_wrapper') == null) {
            this.middleWrapper = document.createElement('div');
            this.middleWrapper.className = 'toasts_wrapper_center toasts_wrapper_middle toast';
            this.middleWrapper.id = 'middle_toast_wrapper';
            floatingUI.appendChild(this.middleWrapper);
        }
        if (document.getElementById('bottomright_toast_wrapper') == null) {
            this.bottomRightWrapper = document.createElement('div');
            this.bottomRightWrapper.className = 'toasts_wrapper_right toasts_wrapper_bottom toast';
            this.bottomRightWrapper.id = 'bottomright_toast_wrapper';
            floatingUI.appendChild(this.bottomRightWrapper);
        }
        if (document.getElementById('bottomleft_toast_wrapper') == null) {
            this.bottomLeftWrapper = document.createElement('div');
            this.bottomLeftWrapper.className = 'toasts_wrapper_left toasts_wrapper_bottom toast';
            this.bottomLeftWrapper.id = 'bottomleft_toast_wrapper';
            floatingUI.appendChild(this.bottomLeftWrapper);
        }
        if (document.getElementById('bottomcenter_toast_wrapper') == null) {
            this.bottomCenterWrapper = document.createElement('div');
            this.bottomCenterWrapper.className = 'toasts_wrapper_center toasts_wrapper_bottom toast';
            this.bottomCenterWrapper.id = 'bottomcenter_toast_wrapper';
            floatingUI.appendChild(this.bottomCenterWrapper);
        }
    }
    showToast(config) {
        if (isNaN(config.duration)) {
            config.duration = 6000;
        }
        const floatingUI = document.getElementById('floating-ui') || document.body;
        if (config.type === 'logout') {
            const div = this.generateLogoutHTML(config);
            floatingUI.appendChild(div);
            document.getElementById('confirm').onclick = () => {
                this.confirm(div, config);
            };
        }
        else {
            const id = this.generateRandomID();
            const div = viewport.isMobile
                ? this.generateMobileToastHTML(config, id)
                : this.generateToastHTML(config, id);
            this.addToastToPage(viewport.isMobile ? 'bottom_center' : config.position, div);
            let duration = 6000;
            if (config.duration) {
                duration = config.duration;
            }
            setTimeout(() => {
                try {
                    this.close(div, config.position);
                }
                catch { }
            }, duration);
        }
    }
    addToastToPage(position, div) {
        if (position === ToastPosition.BOTTOMRIGHT) {
            document.getElementById('bottomright_toast_wrapper').appendChild(div);
        }
        else if (position === ToastPosition.BOTTOMLEFT) {
            document.getElementById('bottomleft_toast_wrapper').appendChild(div);
        }
        else if (position === ToastPosition.BOTTOMCENTER) {
            document.getElementById('bottomcenter_toast_wrapper').appendChild(div);
        }
        else if (position === ToastPosition.MIDDLE) {
            document.getElementById('middle_toast_wrapper').appendChild(div);
        }
        else if (position === ToastPosition.TOP) {
            document.getElementById('top_toast_wrapper').appendChild(div);
        }
    }
    generateToastHTML(config, id) {
        const div = document.createElement('div');
        div.id = 'toast';
        div.className = this.calculateClassName(config);
        if (config.backdrop) {
            div.innerHTML = `
            <dn-notification
                id="${id}"
                type="${config.type}"
                color="${config.color}"
                title="${config.title || ' '}"
                text="${config.text}"
                backdrop="${config.backdrop}"
            ></dn-notification>
        `;
        }
        else {
            div.innerHTML = `
            <dn-notification
                id="${id}"
                type="${config.type}"
                color="${config.color}"
                title="${config.title || ' '}"
                text="${config.text}"
            ></dn-notification>
        `;
        }
        return div;
    }
    generateMobileToastHTML(config, id) {
        const div = document.createElement('div');
        div.id = 'mobile-toast';
        div.className = this.calculateClassName(config);
        div.innerHTML = `
            <div
                class="wrapper"
                style="${this.calculateOverruleStyles()}">
                <div class="snackbar">
                    <div>${config.text}</div>
                </div>
            </div>
        `;
        return div;
    }
    generateLogoutHTML(config) {
        const div = document.createElement('div');
        div.className = 'logout';
        div.innerHTML = `
        <div id="toast" class="${isMobile() ? 'logout_wrapper_mobile' : 'logout_wrapper'}">
            <div class="wrapper danger" style="padding-right: 16px;">
                <div class="toast_header" style="padding-bottom: 12px; border: 0;">
                    <div>${config.title}</div>
                </div>
                <div class="toast_body">
                    <div>${config.text}</div>
                </div>
                <div class="toast_footer">
                    <dn-common-button variant="${Variant.discard}" id="confirm" text="Ok"></dn-common-button>
                </div>
            </div>
        </div>
        `;
        return div;
    }
    calculateClassName(config) {
        let animationClass = "";
        if (isMobile()) {
            animationClass = 'no-animation';
        }
        else {
            if (config.position === ToastPosition.BOTTOMLEFT) {
                animationClass = 'left-animation';
            }
            else if (config.position === ToastPosition.BOTTOMCENTER) {
                animationClass = 'bottom-animation';
            }
            else if (config.position === ToastPosition.BOTTOMRIGHT) {
                animationClass = 'right-animation';
            }
            // } else if (config.position === ToastPosition.TOP) {
            //     animationClass = 'top-animation';
            // } else if (config.position === ToastPosition.MIDDLE) {
            //     animationClass = 'top-animation';
            // }
        }
        return (isMobile() ? 'bottom_center' : config.position) + ' ' + animationClass;
    }
    calculateOverruleStyles() {
        let styles = '';
        if (this.toastOverwriteRules.font) {
            styles += 'font-family: ' + this.toastOverwriteRules.font + ';';
        }
        if (this.toastOverwriteRules.backgroundColor) {
            styles += 'background-color: ' + this.toastOverwriteRules.backgroundColor + ';';
        }
        if (this.toastOverwriteRules.textColor) {
            styles += 'color: ' + this.toastOverwriteRules.textColor + ';';
        }
        return styles;
    }
    confirm(div, config) {
        if (config.type === ToastType.LOGOUT) {
            document.getElementById('body').dispatchEvent(new CustomEvent('notification', { detail: { callback: 'go_to_index' } }));
        }
        this.close(div, config.position);
    }
    close(div, position) {
        div.className = div.className.replace(position, '');
        div.parentNode.removeChild(div);
    }
    generateRandomID() {
        return Math.random().toString(36).substr(2, 9);
    }
}
