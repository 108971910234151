var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { getTranslationByKey } from '@ui/common/js/data/translations_data';
import { setState, getObservable } from '../data/login_data';
import { SetStateMessages, GetObservableMessages } from '../models/models';
import { isMobile } from '@ui/common/js/utils/agent_checker';
import debounce from 'lodash.debounce';
import ToastService, { defaultRules, ToastColor, ToastPosition, ToastType } from '@ui/common/js/notifications/new/notification_module';
let LoginForm = class LoginForm extends LitElement {
    constructor() {
        super();
        this.toastService = new ToastService(defaultRules);
        this.debouncedWarningUpdate = debounce((info) => {
            this.warning = info;
        }, 200);
        document.getElementById('body2').addEventListener('language_selected', () => {
            super.requestUpdate();
        });
    }
    createRenderRoot() {
        return this;
    }
    render() {
        return html `
            <div class="${isMobile() ? 'modal_mobile' : 'modal'}" id="login_form">
                <div style="margin-bottom: 3em;">
                    <h2>${getTranslationByKey('login_title')}</h2>
                </div>
                <div>
                    <dn-login-input-field
                        label="${getTranslationByKey('username_placeholder')}"
                        @keypressed="${(e) => this.emailChanged(e.detail.value)}"
                        ?valid="${this.warning === ''}"
                        hasFocus
                    ></dn-login-input-field>
                    <dn-login-input-field
                        label="${getTranslationByKey('pw_placeholder')}"
                        variant="password"
                        @keypressed="${(e) => this.password = e.detail.value}"
                        ?valid="${this.warning === ''}"
                        invalidText="${getTranslationByKey(this.warning)}"
                        hasFocus
                    ></dn-login-input-field>
                    <div style="height: 1em;"></div>
                    <dn-button
                        text="${getTranslationByKey('login_button')}"
                        @click="${() => this.submitLoginForm()}"
                    ></dn-button>
                </div>
                <div class="modal_footer">
                    <dn-button
                        text="${getTranslationByKey('forgot_password_button')}"
                        variant="dn-button--tertiary"
                        @click="${() => this.selectForgotPassword()}"
                        noShadow
                    ></dn-button>
                    <dn-button
                        text="${getTranslationByKey('request_access_button')}"
                        variant="dn-button--tertiary"
                        style="${isMobile() ? 'margin-top: 10px;' : 'margin-top: 2em;'} margin-bottom: 1em;"
                        @click="${() => this.selectRequestAccessForm()}"
                        noShadow
                    ></dn-button>
                </div>
            </div>
        `;
    }
    emailChanged(email) {
        this.email = email;
        this.warning = '';
    }
    passwordChanged(password) {
        this.password = password;
        this.warning = '';
    }
    selectForgotPassword() {
        super.dispatchEvent(new CustomEvent('formSelectionChanged', {
            detail: {
                form: 'forgot_password_form',
            },
        }));
    }
    selectRequestAccessForm() {
        super.dispatchEvent(new CustomEvent('formSelectionChanged', {
            detail: {
                form: 'request_access_form',
            },
        }));
    }
    submitLoginForm() {
        this.warning = '';
        setState({
            type: SetStateMessages.LOGIN,
            data: {
                username: this.email,
                password: this.password,
                LoginForm: 'POST'
            },
        });
    }
    firstUpdated() {
        getObservable(GetObservableMessages.FAILED_LOGIN_INFORMATION).subscribe(info => {
            this.debouncedWarningUpdate(info);
        });
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const forcedLogout = urlParams.get('forced');
        if (forcedLogout) {
            if (forcedLogout.toLowerCase() === 'true') {
                window.localStorage.clear();
                window.sessionStorage.clear();
                this.toastService.showToast({
                    position: ToastPosition.TOP,
                    title: getTranslationByKey('log_out_message_title'),
                    text: getTranslationByKey('log_out_message_content'),
                    type: ToastType.LOGOUT,
                    color: ToastColor.DANGER,
                });
            }
        }
        document.addEventListener('keypress', (e) => this.handleEnterPress(e));
    }
    handleEnterPress(e) {
        if (e.key === 'Enter' && document.getElementById('login_form')) {
            this.submitLoginForm();
        }
    }
};
__decorate([
    property({ type: String })
], LoginForm.prototype, "warning", void 0);
LoginForm = __decorate([
    customElement('login-form')
], LoginForm);
export default LoginForm;
