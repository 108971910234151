var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { setTranslations, setSelectedLanguage } from '@ui/common/js/data/translations_data';
let TranslationsNode = class TranslationsNode extends LitElement {
    constructor() {
        super();
        this.translations = {
            Translations: [],
            Language: 'nl'
        };
    }
    render() {
        return html `
            <div style="display: none;">translations</div>
        `;
    }
    firstUpdated() {
        setSelectedLanguage(this.translations.Language);
        setTranslations(this.translations.Translations);
    }
};
__decorate([
    property({ type: Object })
], TranslationsNode.prototype, "translations", void 0);
TranslationsNode = __decorate([
    customElement('translations-node')
], TranslationsNode);
export default TranslationsNode;
