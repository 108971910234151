import { svg } from 'lit';
export const icons = {
    caret_down: svg `
        <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg" style="pointer-events: none;">
            <path d="M1.15813 3.20372L6.22977 8.27588C6.23029 8.27588 6.23029 8.27641 6.23082 8.27694C6.26093 8.30705 6.29473 8.32976 6.32695 8.35511C6.73524 8.67995 7.31836 8.66832 7.71344 8.31919C7.72981 8.30441 7.7483 8.29278 7.7652 8.27694L12.8379 3.20425C13.261 2.78117 13.261 2.09506 12.8368 1.67145C12.4138 1.24837 11.7276 1.24837 11.304 1.67145L6.99775 5.97828L2.69092 1.67039C2.26785 1.24626 1.58173 1.24626 1.15813 1.67039C0.735051 2.09347 0.735051 2.77958 1.15813 3.20372Z" fill="#6D7381"/>
        </svg>
    `,
    caret_up: svg `
        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg" style="pointer-events: none;">
            <path d="M12.838 5.73867L7.76633 0.6665L7.76527 0.665443C7.73517 0.635337 7.70136 0.612625 7.66914 0.587272C7.26085 0.262438 6.67774 0.274058 6.28266 0.623188C6.26628 0.637978 6.2478 0.649598 6.23089 0.665443L1.1582 5.73814C0.735123 6.16121 0.735122 6.84733 1.15926 7.27093C1.58233 7.69401 2.26845 7.69401 2.69205 7.27093L6.99835 2.96411L11.3052 7.27199C11.7282 7.69612 12.4144 7.69612 12.838 7.27199C13.261 6.84891 13.261 6.1628 12.838 5.73867Z" fill="#6D7381"/>
        </svg>
    `,
    logs_icon: svg `
        <svg
            width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.0918 5.85938H24.9512V19.1406H19.0918V5.85938Z" fill="#B4B6B8"/>
            <path d="M21.3932 24.6445C21.6776 25.1183 22.3654 25.1177 22.6494 24.6445C25.0284 20.6793 24.951 20.8851 24.951 20.6055H19.0916C19.0916 20.8855 19.0155 20.6816 21.3932 24.6445Z" fill="#B4B6B8"/>
            <path d="M22.7539 0H21.2891C20.0775 0 19.0918 0.985718 19.0918 2.19727V4.39453H24.9512V2.19727C24.9512 0.985718 23.9655 0 22.7539 0Z" fill="#B4B6B8"/>
            <path d="M8.83789 5.12695C8.83789 5.71194 8.18386 6.05965 7.6992 5.73635L5.9082 4.54235L4.1172 5.73635C3.63083 6.0606 2.97852 5.71041 2.97852 5.12695V0H0.78125C0.376701 0 0.0488281 0.327873 0.0488281 0.732422V24.2676C0.0488281 24.6721 0.376701 25 0.78125 25H11.7676V0H8.83789V5.12695Z" fill="#B4B6B8"/>
            <path d="M6.31447 3.05271L7.37305 3.75843V0H4.44336V3.75843L5.50194 3.05271C5.74799 2.88868 6.06842 2.88868 6.31447 3.05271Z" fill="#B4B6B8"/>
            <path d="M13.9648 0H13.2324V25H13.9648C15.9842 25 17.627 23.3572 17.627 21.3379V3.66211C17.627 1.6428 15.9842 0 13.9648 0Z" fill="#B4B6B8"/>
        </svg>
    `,
    visitor_dashboard_icon: svg `
        <svg width="30" height="30" viewBox="0 0 40 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M36.4844 0H3.51562C1.57703 0 0 1.57703 0 3.51562V26.9531C0 28.8917 1.57703 30.4688 3.51562 30.4688H36.4844C38.423 30.4688 40 28.8917 40 26.9531V3.51562C40 1.57703 38.423 0 36.4844 0ZM15.2344 25.7812C9.55297 25.7812 4.6875 21.1348 4.6875 15.2344C4.6875 9.41852 9.41852 4.6875 15.2344 4.6875C21.0502 4.6875 25.7812 9.41852 25.7812 15.2344C25.7812 21.1802 20.8595 25.7812 15.2344 25.7812ZM34.1406 23.4375H29.2969C28.6491 23.4375 28.125 22.9134 28.125 22.2656C28.125 21.6179 28.6491 21.0938 29.2969 21.0938H34.1406C34.7884 21.0938 35.3125 21.6179 35.3125 22.2656C35.3125 22.9134 34.7884 23.4375 34.1406 23.4375ZM34.1406 18.75H29.2969C28.6491 18.75 28.125 18.2259 28.125 17.5781C28.125 16.9304 28.6491 16.4062 29.2969 16.4062H34.1406C34.7884 16.4062 35.3125 16.9304 35.3125 17.5781C35.3125 18.2259 34.7884 18.75 34.1406 18.75ZM34.1406 14.0625H29.2969C28.6491 14.0625 28.125 13.5384 28.125 12.8906C28.125 12.2429 28.6491 11.7188 29.2969 11.7188H34.1406C34.7884 11.7188 35.3125 12.2429 35.3125 12.8906C35.3125 13.5384 34.7884 14.0625 34.1406 14.0625ZM34.1406 9.375H29.2969C28.6491 9.375 28.125 8.85086 28.125 8.20312C28.125 7.55539 28.6491 7.03125 29.2969 7.03125H34.1406C34.7884 7.03125 35.3125 7.55539 35.3125 8.20312C35.3125 8.85086 34.7884 9.375 34.1406 9.375Z" fill="white"/>
            <path d="M15.2344 7.03125C10.7116 7.03125 7.03125 10.7116 7.03125 15.2344C7.03125 16.9803 7.58898 18.593 8.52273 19.9246C9.35211 18.7317 10.4609 17.7824 11.758 17.178C11.0134 16.348 10.5469 15.263 10.5469 14.0625C10.5469 11.4773 12.6491 9.375 15.2344 9.375C17.8196 9.375 19.9219 11.4773 19.9219 14.0625C19.9219 15.263 19.4554 16.348 18.7108 17.178C20.0078 17.7824 21.1166 18.7317 21.946 19.9246C22.8798 18.593 23.4375 16.9803 23.4375 15.2344C23.4375 10.7116 19.7571 7.03125 15.2344 7.03125Z" fill="white"/>
            <path d="M15.2344 11.7188C13.9423 11.7188 12.8906 12.7705 12.8906 14.0625C12.8906 15.3545 13.9423 16.4062 15.2344 16.4062C16.5264 16.4062 17.5781 15.3545 17.5781 14.0625C17.5781 12.7705 16.5264 11.7188 15.2344 11.7188Z" fill="white"/>
            <path d="M15.2345 18.75C13.1231 18.75 11.2203 19.8634 10.1802 21.6455C11.5778 22.7502 13.3192 23.4375 15.2345 23.4375C17.1498 23.4375 18.8911 22.7502 20.2888 21.6455C19.2486 19.8634 17.3459 18.75 15.2345 18.75Z" fill="white"/>
        </svg>
    `,
    missed_call_icon: svg `
        <svg
            class="svg-icon" 
            style="width: 1em; height: 1em; vertical-align: middle;fill: currentColor;overflow: hidden;"
            viewBox="0 0 1024 1024" 
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M256 319.573333a42.666667 42.666667 0 0 0 42.666667-42.666666V251.733333l122.88 122.88a128 128 0 0 0 180.906666 0l195.84-195.84a42.666667 42.666667 0 0 0 0-60.16 42.666667 42.666667 0 0 0-60.586666 0l-195.413334 195.413334a42.666667 42.666667 0 0 1-60.586666 0L358.826667 191.573333H384a42.666667 42.666667 0 0 0 0-85.333333H256a42.666667 42.666667 0 0 0-39.253333 26.026667 46.506667 46.506667 0 0 0-3.413334 16.64v128a42.666667 42.666667 0 0 0 42.666667 42.666666z m680.106667 314.026667a694.186667 694.186667 0 0 0-848.213334 0 114.773333 114.773333 0 0 0-42.666666 85.333333 113.493333 113.493333 0 0 0 33.28 88.32l75.093333 76.8a114.346667 114.346667 0 0 0 147.626667 11.946667l20.053333-13.653333a346.88 346.88 0 0 1 42.666667-23.466667 78.933333 78.933333 0 0 0 42.666666-98.133333l-3.84-10.24a447.573333 447.573333 0 0 1 222.72 0l-3.84 10.24a78.933333 78.933333 0 0 0 42.666667 98.133333 346.88 346.88 0 0 1 42.666667 23.466667l20.053333 13.653333a110.08 110.08 0 0 0 65.706667 21.333333 116.053333 116.053333 0 0 0 81.92-33.706666l77.226666-77.653334a113.493333 113.493333 0 0 0 29.44-87.893333 114.773333 114.773333 0 0 0-45.226666-84.48z m-48.64 112.64L810.666667 823.466667a29.013333 29.013333 0 0 1-36.693334 4.266666c-8.106667-5.973333-16.213333-11.52-25.173333-17.066666a497.066667 497.066667 0 0 0-46.506667-26.026667l17.066667-46.506667a42.666667 42.666667 0 0 0-25.6-54.613333 529.92 529.92 0 0 0-362.666667 0 42.666667 42.666667 0 0 0-25.6 54.613333l17.066667 46.933334a418.133333 418.133333 0 0 0-46.933333 25.6l-24.746667 17.066666a28.16 28.16 0 0 1-37.546667-4.266666l-76.8-77.226667A28.586667 28.586667 0 0 1 128 725.333333a32.426667 32.426667 0 0 1 11.946667-22.613333 609.706667 609.706667 0 0 1 744.106666 0A32.426667 32.426667 0 0 1 896 725.333333a28.586667 28.586667 0 0 1-8.533333 20.906667z"
            />
        </svg>
    `,
};
